import React from 'react';
import PropTypes from 'prop-types';

import { Layout } from '../components/common';
import { MetaData } from '../components/common/meta';
import { Link } from 'gatsby';

const Blog = ({ data, location, pageContext }) => {
  const post = data.allGhostPost.edges[0];
  const page = data.allGhostPage.edges[0];

  return (
    <>
      <MetaData location={location} />
      <Layout isHome={true} bodyClass="home">
        {/* <div className="container"> */}
        <div className="intro-container">
          <div className="fullsizeintro">
          <Link to={page.node.slug}>
            <div className="half">
              <div
                className="image"
                style={{
                  backgroundImage: `url(${page.node.feature_image})`,
                }}
              />
              <div className="description">
                <p className="label">Najnowsze zdjęcia</p>
                <h2 className="title">{page.node.title}</h2>
              </div>
            </div>
            </Link>
            <Link to={`/blog/${post.node.slug}`}>
            <div className="half">
              <div
                className="image"
                style={{
                  backgroundImage: `url(${post.node.feature_image})`,
                }}
              />
              <div className="description">
              <p className="label">Ostatni post</p>
                <h2 className="title">{post.node.title}</h2>
              </div>
            </div>
            </Link>
          </div>
        </div>
        {/* </div> */}
      </Layout>
    </>
  );
};

Blog.propTypes = {
  data: PropTypes.shape({
    allGhostPost: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.object,
};

export const pageQuery = graphql`
  query GhostIndexPageQuery {
    allGhostPage(
      filter: {
        tags: {
          elemMatch: { name: { in: ["people", "pregnancy", "bachelorette"] } }
        }
      }
      sort: { fields: published_at, order: DESC }
      limit: 1
    ) {
      edges {
        node {
          ...GhostPageFields
        }
      }
    }
    allGhostPost(sort: { order: DESC, fields: [published_at] }, limit: 1) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
  }
`;

export default Blog;
